import axios from "axios";
import Banner from "components/home/Banner";
import Portfolio from "components/home/Portfolio";
import Service from "components/home/Service";
import ThreeBanner from "components/home/ThreeBanner";
import { useEffect, useState } from "react";

const Home = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    axios.get("/api/board/main").then((res) => setData(res.data.data.content));
  }, []);
  return (
    <div>
      <Banner />
      <ThreeBanner />
      <Service />
      <Portfolio data={data} />
    </div>
  );
};

export default Home;
