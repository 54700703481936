import { Outlet, useLocation } from "react-router-dom";

import MHeader from "./header/MHeader";
import MFooter from "./footer/MFooter";
import AHeader from "./header/AHeader";
import ANav from "./nav/ANav";

const Layout = () => {
  const { pathname } = useLocation();

  console.log(pathname);

  if (pathname.includes("login")) {
    return (
      <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
        <Outlet />
      </div>
    );
  }

  if (
    pathname.includes("admin") &&
    (localStorage.getItem("email") === undefined ||
      localStorage.getItem("email") === null)
  ) {
    return (
      <div className=" relative w-full h-screen grid items-center justify-center">
        <div>
          <p className="mb-5">관리자 로그인이 필요합니다.</p>
          <div
            onClick={() => (window.location.href = "/admin/login")}
            className="relative w-fit h-fit py-1.5 px-10 border border-black mx-auto cursor-pointer hover:bg-black hover:text-white"
          >
            <p className="lg:text-base">관리자 로그인</p>
          </div>
        </div>
      </div>
    );
  }

  if (pathname.includes("admin") && localStorage.getItem("email")) {
    return (
      <div className="lg:flex w-full h-full pb-5 max-w-[100vw] overflow-hidden min-h-screen">
        <ANav />
        <AHeader />
        <div className="relative w-full h-full pt-32 lg:pt-32 lg:px-5 max-w-screen-2xl">
          <Outlet />
          {/* <AFooter /> */}
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
      <MHeader />
      <Outlet />
      <MFooter />
    </div>
  );
};

export default Layout;
