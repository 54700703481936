import { useEffect } from 'react';

declare global {
    interface Window {
        kakao: any;
    }
}

const KakaoMap = () => {
    useEffect(() => {
        window.kakao.maps.load(() => {
            // 지도 생성
            const mapContainer = document.getElementById('map'), // 지도를 표시할 div
                mapOption = {
                    center: new window.kakao.maps.LatLng(37.5104685, 126.6727852), // 지도의 중심좌표
                    level: 1, // 지도의 확대 레벨
                };

            const map = new window.kakao.maps.Map(mapContainer, mapOption);
            const markerPosition = new window.kakao.maps.LatLng(37.5104685, 126.6727852);

            const imageSrc = '/images/marker.png'; // 마커이미지의 주소입니다
            const imageSize = new kakao.maps.Size(160, 130); // 마커이미지의 크기입니다
            // const imageOption = { offset: new kakao.maps.Point(27, 69) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

            // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
            var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);

            // 마커를 생성합니다
            var marker = new kakao.maps.Marker({
                map: map,
                position: markerPosition,
                image: markerImage, // 마커이미지 설정
                // text: '희우장학재단',
            });

            // 지도의 중심을 결과값으로 받은 위치로 이동
            marker.setMap(map);
        });
    });
    return <div id="map" className=" relative w-full h-64 lg:h-[600px] mb-5 z-0"></div>;
};

export default KakaoMap;
