import KakaoMap from 'components/sub/KakaoMap';
import SubTitle from 'components/sub/SubTitle';

const Location = () => {
    return (
        <div className=" relative w-full h-full">
            <SubTitle src="/images/sub2.jpg" category="재단소개" title="오시는 길" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <div id="title">
                        <p className="font-black opacity-55 mb-2">Location</p>
                        <h3>(재)김병해 서로장학재단에 오시는 길을 안내해드립니다.</h3>
                    </div>
                    <div
                        data-aos="fade-up"
                        className="relative w-full h-full max-w-[var(--container-width)] mx-auto my-5 lg:my-10 aos-init aos-animate"
                    >
                        <KakaoMap />
                        <div className="lg:absolute right-5 bottom-5">
                            <div className=" relative w-full min-w-96 h-fit bg-white bg-opacity-80 py-10 px-5 border-t border-b border-gray-200">
                                <h5>CONTACT</h5>
                                <h3>010-9099-3574</h3>
                                <p className="my-3">인천광역시 서구 가정로 288 2층</p>
                                <div className="opacity-80">
                                    <small>Tel.&nbsp;010-9099-3574</small>&nbsp;&nbsp;|&nbsp;{' '}
                                    <small>Email.&nbsp;hyd8080@cu.co.kr</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
