import { Pagination } from "@mantine/core";
import axios from "axios";
import BoardCategory from "./BoardCategory";
import { dateFormat } from "const/dateFormat";
import { JoinNotice } from "const/table";
import { useEffect, useState } from "react";

const BoardList = () => {
  const [categoryId, setCategoryId] = useState<number>(0);
  const [data, setData] = useState<JoinNotice[] | null>(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    axios
      .get("/api/board/all?page=0&size=10")
      .then((res) => setData(res.data.data.content));
    axios
      .get("/api/board/total?categoryId=0")
      .then((res) => setTotal(res.data.data));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/board/category/${categoryId}?page=${page - 1}&size=10`)
      .then((res) => {
        if (res.data.code === "0000") {
          setData(res.data.data.content);
        }
      });
  }, [categoryId, page]);

  useEffect(() => {
    axios.get(`/api/board/total?categoryId=${categoryId}`).then((res) => {
      if (res.data.code === "0000") {
        setTotal(res.data.data);
      }
    });
  }, [categoryId]);

  return (
    <div className="relative w-full h-full">
      <div className="flex justify-between items-center flex-wrap lg:flex-nowrap">
        <BoardCategory categoryId={categoryId} setCategoryId={setCategoryId} />
        <div
          onClick={() => (window.location.href = `/admin/post`)}
          className="ml-auto mt-5 lg:mb-0 lg:ml-0 relative w-fit h-fit border flex justify-center items-center py-1 px-5 rounded-full border-black bg-black hover:bg-white text-white hover:text-black transition-colors duration-300 cursor-pointer"
        >
          <p>게시글 작성</p>
        </div>
      </div>
      <ul className="relative w-full h-full mt-5">
        {data &&
          data.map((v, i) => (
            <li
              key={v.category.name! + i}
              onClick={() => (window.location.href = `/admin/board/${v.id}`)}
              className="borderLine relative w-full h-fit py-5 px-2 border-b grid gap-5"
            >
              <span className="relative w-full h-fit flex justify-between items-start">
                <p>
                  <b>{v.title}</b>
                </p>
                <p className="lg:text-sm text-neutral-500">
                  {dateFormat(v.createdAt)}
                </p>
              </span>
              <small>{v.category.name}</small>
            </li>
          ))}
      </ul>

      <div className=" relative w-fit h-fit mx-auto mt-10">
        {total > 0 && (
          <Pagination
            total={Math.ceil(total / 10) ? Math.ceil(total / 10) : 1}
            value={page}
            onChange={setPage}
            color="gray"
          />
        )}
      </div>
    </div>
  );
};

export default BoardList;
