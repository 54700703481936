import BoardMian from "components/admin/BoardMian";
import InquiryList from "components/admin/InquiryList";
import ThreeInquiry from "components/admin/ThreeInquiry";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className="relative w-full max-w-[100vw] h-full">
      <ThreeInquiry />
      <div className="relative w-full h-full grid gap-5">
        <div className="border border-slate-100 relative w-full h-full p-5 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-5">
            <p>
              <b>게시판</b>
            </p>
            <Link to="/admin/board">
              <small className="text-neutral-500">전체보기</small>
            </Link>
          </div>
          <BoardMian category={0} />
        </div>
        {/* <div className="border border-slate-100 relative w-full h-full p-5 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-5">
            <p>
              <b>장학신청</b>
            </p>
            <Link to="/admin/board">
              <small className="text-neutral-500">전체보기</small>
            </Link>
          </div>
          <BoardMian category={2} />
        </div> */}
        <div className="border border-slate-100 mt-5 relative w-full h-full p-5 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-5">
            <p>
              <b>고객문의</b>
            </p>
            <Link to="/admin/inquiry">
              <small className="text-neutral-500">전체보기</small>
            </Link>
          </div>
          <InquiryList />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
