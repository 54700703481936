import { main_all_category } from "const/category";
import React, { SetStateAction } from "react";

const BoardCategory = ({
  categoryId,
  setCategoryId,
}: {
  categoryId: number;
  setCategoryId: React.Dispatch<SetStateAction<number>>;
}) => {
  return (
    <ul className="flex items-center gap-5 flex-wrap lg:flex-nowrap">
      {main_all_category.map((v, i) => (
        <li key={i} className="cursor-pointer">
          <p
            onClick={() => setCategoryId(v.id)}
            className={
              categoryId === v.id
                ? "text-point lg:text-lg"
                : "text-neutral-500 lg:text-lg"
            }
          >
            {v.name}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default BoardCategory;
