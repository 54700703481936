import { IconCheckbox } from "@tabler/icons-react";
import SubTitle from "components/sub/SubTitle";
import { useState } from "react";

const Business = () => {
  const [tab, setTab] = useState(2);
  return (
    <div className=" relative w-full h-full">
      <SubTitle src="/images/sub4.jpg" category="재단소개" title="재단사업" />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <div id="title">
            <h3>
              재단법인 김병해 서로장학재단은 <br /> 교육 소외계층에 대한 지원 및
              인재 발굴을 위해 노력합니다.
            </h3>
          </div>
          <div className=" relative w-full h-full lg:grid grid-cols-2 grid-rows-3 lg:grid-rows-1 gap-5 mt-10">
            <div
              data-aos="fade-up"
              onClick={() => setTab(2)}
              className="relative w-full h-44 lg:h-72 xl:h-96 mb-2 bg-gray-300 text-white cursor-pointer"
            >
              <div className=" absolute left-0 top-0 w-full h-full bg-black bg-opacity-50 hover:bg-opacity-0 text-white hover:text-black z-10 transition-all duration-500">
                <p className=" absolute right-3 top-3 lg:right-5 lg:top-5 z-20">
                  소외계층 학생 장학금 지원 사업
                </p>
              </div>
              <img
                className=" relative w-full h-full object-cover object-center z-0"
                src="/images/service1.jpg"
                alt="service1"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="50"
              onClick={() => setTab(1)}
              className="hidden lg:block relative w-full h-44 lg:h-72 xl:h-96 mb-2 bg-gray-300 text-white cursor-pointer"
            >
              <div className=" absolute left-0 top-0 w-full h-full bg-black bg-opacity-50 hover:bg-opacity-0 text-white hover:text-black z-10 transition-all duration-500">
                <p className=" absolute right-3 top-3 lg:right-5 lg:top-5 z-20">
                  성적 우수자 학생 장학금 지원 사업
                </p>
              </div>
              <img
                className=" relative w-full h-full object-cover object-center z-0"
                src="/images/service2.jpg"
                alt="service1"
              />
            </div>
          </div>
          <div className="block lg:hidden relative w-full h-full p-10 bg-[var(--gray-color)] mb-5">
            <h5>소외계층 학생 장학금 지원 사업</h5>
            <div className="grid gap-3 mt-5">
              <p className="flex items-center gap-2">
                <IconCheckbox color="var(--point-color)" />
                장학재단 사업1 설명
              </p>
              <p className="flex items-center gap-2">
                <IconCheckbox color="var(--point-color)" />
                장학재단 사업1 설명
              </p>
              <p className="flex items-center gap-2">
                <IconCheckbox color="var(--point-color)" />
                장학재단 사업1 설명
              </p>
              <p className="flex items-center gap-2">
                <IconCheckbox color="var(--point-color)" />
                장학재단 사업1 설명
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="50"
            className="block lg:hidden relative w-full h-44 lg:h-72 xl:h-96 mb-2 bg-gray-300 text-white cursor-pointer"
          >
            <div className=" absolute left-0 top-0 w-full h-full bg-black bg-opacity-50 hover:bg-opacity-0 text-white hover:text-black z-10 transition-all duration-500">
              <p className=" absolute right-3 top-3 lg:right-5 lg:top-5 z-20">
                성적 우수자 학생 장학금 지원 사업
              </p>
            </div>
            <img
              className=" relative w-full h-full object-cover object-center z-0"
              src="/images/service2.jpg"
              alt="service1"
            />
          </div>
          <div className="block lg:hidden relative w-full h-full p-10 bg-[var(--gray-color)] mb-5">
            <h5>성적 우수자 학생 장학금 지원 사업</h5>
            <div className="grid gap-3 mt-5">
              <p className="flex items-center gap-2">
                <IconCheckbox color="var(--point-color)" />
                장학재단 사업2 설명
              </p>
              <p className="flex items-center gap-2">
                <IconCheckbox color="var(--point-color)" />
                장학재단 사업2 설명
              </p>
              <p className="flex items-center gap-2">
                <IconCheckbox color="var(--point-color)" />
                장학재단 사업2 설명
              </p>
              <p className="flex items-center gap-2">
                <IconCheckbox color="var(--point-color)" />
                장학재단 사업2 설명
              </p>
            </div>
          </div>
          <div className="hidden lg:block relative w-full h-full p-10 bg-[var(--gray-color)]">
            {tab === 2 ? (
              <div className=" relative w-full h-full">
                <h5>소외계층 학생 장학금 지원 사업</h5>
                <div className="grid gap-3 mt-5">
                  <p className="flex items-center gap-2">
                    <IconCheckbox color="var(--point-color)" />
                    장학재단 사업1 설명
                  </p>
                  <p className="flex items-center gap-2">
                    <IconCheckbox color="var(--point-color)" />
                    장학재단 사업1 설명
                  </p>
                  <p className="flex items-center gap-2">
                    <IconCheckbox color="var(--point-color)" />
                    장학재단 사업1 설명
                  </p>
                  <p className="flex items-center gap-2">
                    <IconCheckbox color="var(--point-color)" />
                    장학재단 사업1 설명
                  </p>
                </div>
              </div>
            ) : (
              <div className=" relative w-full h-full">
                <h5>성적 우수자 학생 장학금 지원 사업</h5>
                <div className="grid gap-3 mt-5">
                  <p className="flex items-center gap-2">
                    <IconCheckbox color="var(--point-color)" />
                    장학재단 사업2 설명
                  </p>
                  <p className="flex items-center gap-2">
                    <IconCheckbox color="var(--point-color)" />
                    장학재단 사업2 설명
                  </p>
                  <p className="flex items-center gap-2">
                    <IconCheckbox color="var(--point-color)" />
                    장학재단 사업2 설명
                  </p>
                  <p className="flex items-center gap-2">
                    <IconCheckbox color="var(--point-color)" />
                    장학재단 사업2 설명
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
