import {
  IconCalendarSmile,
  IconCategory,
  IconClockHour1,
  IconEye,
} from "@tabler/icons-react";
import axios from "axios";
import SubTitle from "components/sub/SubTitle";
import { dateFormat } from "const/dateFormat";
import { JoinNotice } from "const/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const OneNotice = () => {
  const params = useParams();

  const [data, setData] = useState<JoinNotice | null>(null);

  useEffect(() => {
    params.id &&
      axios.get(`/api/board/${params.id}`).then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className=" relative w-full h-full">
      <SubTitle src="/images/sub5.jpg" category="커뮤니티" title="재단소식" />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-7 lg:py-14">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <div className=" relative w-full h-full my-5 lg:my-10">
            <h4 className="text-center py-7 lg:py-14">{data && data.title}</h4>
            <div className="relative w-full h-full bg-[var(--gray-color)] px-2 py-3 flex justify-between rounded-sm">
              <small className="flex items-center gap-1 whitespace-nowrap overflow-hidden overflow-ellipsis">
                <IconCategory />
                {data && data.category.name}
              </small>
              <small className="flex items-center gap-1 whitespace-nowrap">
                <IconClockHour1 />
                {data && dateFormat(data.createdAt)}&nbsp; &nbsp; &nbsp;
                <IconEye />
                {data && data.viewCount}
              </small>
            </div>
            <div className="relative w-full h-full px-2 py-5 lg:py-10">
              {data && data.startDate && data.category.id === 2 && (
                <div className="relative w-full flex gap-5 pb-5">
                  <IconCalendarSmile />
                  <p className="hidden lg:block">
                    <b>신청기간</b>
                  </p>
                  <p>
                    <b>{dateFormat(data.startDate)}</b>
                  </p>
                  <p>
                    <b>~</b>
                  </p>
                  <p>
                    <b>{dateFormat(data.endDate!)}</b>
                  </p>
                </div>
              )}
              <div
                className="contents"
                dangerouslySetInnerHTML={{
                  __html: data ? data?.contents : "",
                }}
              ></div>
            </div>
          </div>
          {data && data.files[0] && (
            <a
              href={`/api/board/download?id=${data.files[0].id}`}
              className="relative w-full h-fit"
              download
            >
              <div className="relative w-full h-fit border p-3 shadow-sm rounded-sm lg:hover:shadow-md">
                <p className="lg:text-lg">{data.files[0].originalName}</p>
                <small className="lg:text-sm text-neutral-500">
                  DATE : {dateFormat(data.createdAt)}
                </small>
              </div>
            </a>
          )}
          <div className="relative w-full h-fit max-w-96 mx-auto my-14 lg:my-28">
            <button
              type="button"
              onClick={() => (window.location.href = "/notice")}
              className=" relative w-full h-fit bg-[var(--light-color)] py-3 rounded-sm"
            >
              <p className="font-black">목록으로</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneNotice;
