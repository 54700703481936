import BoardList from "components/admin/BoardList";

const Board = () => {
  return (
    <div>
      <BoardList />
    </div>
  );
};

export default Board;
