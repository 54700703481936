import SubTitle from 'components/sub/SubTitle';

const Greetings = () => {
    return (
        <div className=" relative w-full h-full">
            <SubTitle src="/images/sub1.jpg" category="재단소개" title="인사말" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <div id="title">
                        <p className="font-black opacity-55 mb-2">Greetings</p>
                        <h3>재단법인 김병해 서로장학재단 홈페이지 방문을 진심으로 환영합니다.</h3>
                    </div>
                    <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
                        <p>
                            안녕하세요. 재단법인 김병해 서로장학재단의 이사장 김병해입니다. 재단법인 김병해 서로장학재단
                            홈페이지 방문을 진심으로 환영합니다.
                        </p>
                        <p className="hidden lg:block">
                            재단법인 김병해 서로장학재단은 출연자이신 김병해 님의 사회봉사 정신을 받들어 장학금 후원으로
                            인재 양성을 통해 국가에 큰 성장을 지원하고자 하는 뜻에 따라 본 재단법인을 설립했습니다.
                            <span>
                                재단법인 김병해 서로장학재단은 환경이 유복하지 않은 학생들이 학업에만 정진할 수 있도록
                                장학금을 지원하여 그 재능과 뜻을 펼칠 수 있도록 배움의 기회를 제공하는 것이 그 설립
                                목적입니다.
                            </span>
                        </p>
                        <div className=" relative w-full h-fit grid lg:grid-cols-2 gap-5">
                            <div data-aos="fade-up" className="relative w-full h-52 lg:h-[400px] aos-init">
                                <img
                                    className=" relative w-full h-full object-cover object-center"
                                    src="/images/banner2.jpg"
                                    alt="about"
                                />
                            </div>
                            <p className="lg:hidden">
                                재단법인 김병해 서로장학재단은 출연자이신 김병해 님의 사회봉사 정신을 받들어 장학금
                                후원으로 인재 양성을 통해 국가에 큰 성장을 지원하고자 하는 뜻에 따라 본 재단법인을
                                설립했습니다.
                            </p>
                            <div data-aos="fade-up" className="relative w-full h-52 lg:h-[400px] aos-init">
                                <img
                                    className=" relative w-full h-full object-cover object-center"
                                    src="/images/banner1.jpg"
                                    alt="about"
                                />
                            </div>
                            <p className="lg:hidden">
                                재단법인 김병해 서로장학재단은 환경이 유복하지 않은 학생들이 학업에만 정진할 수 있도록
                                장학금을 지원하여 그 재능과 뜻을 펼칠 수 있도록 배움의 기회를 제공하는 것이 그 설립
                                목적입니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Greetings;
