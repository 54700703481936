import InquiryList from "components/admin/InquiryList";
import ThreeInquiry from "components/admin/ThreeInquiry";

const InquiryLists = () => {
  return (
    <div>
      <ThreeInquiry />
      <InquiryList />
    </div>
  );
};

export default InquiryLists;
