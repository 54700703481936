import { Link } from "react-router-dom";

const MFooter = () => {
  return (
    <footer className=" relative w-full h-full px-[calc((100%-var(--container))/2)] bg-[#181818] text-white xl:pb-10">
      <div className="relative w-full h-fit max-w-[var(--container-width)] mx-auto py-16">
        <div className=" relative w-fit h-full max-h-5 lg:max-h-7 mx-auto">
          <img
            alt="logo"
            src={process.env.PUBLIC_URL + "/images/logo_white.png"}
            className=" relative w-auto h-full max-h-5 lg:max-h-7 object-contain"
          />
        </div>
        <div className=" relative w-full h-fit flex justify-center items-center gap-7 my-10">
          <Link
            to={"/about/greetings"}
            className="opacity-70 hover:opacity-100"
          >
            <small>재단소개</small>
          </Link>
          <small>|</small>
          <Link to={"/business"} className="opacity-70 hover:opacity-100">
            <small>재단사업</small>
          </Link>
          <small>|</small>
          <Link to={"/notice"} className="opacity-70 hover:opacity-100">
            <small>커뮤니티</small>
          </Link>
          <small>|</small>
          <Link
            to={
              "https://www.nts.go.kr/nts/cm/cntnts/cntntsView.do?mi=13386&cntntsId=109155"
            }
            target="_blank"
            className="opacity-70 hover:opacity-100"
          >
            <small>공익위반신고</small>
          </Link>
        </div>
        <div className="flex justify-center items-center gap-2 mb-2 lg:mb-0 lg:gap-5 flex-wrap lg:flex-nowrap opacity-90">
          <small className="font-bold">(재)김병해&nbsp;서로장학재단</small>
          <small>|</small>
          <small>주소: 인천광역시 서구 가정로 288 2층</small>
        </div>
        <div className="flex justify-center items-center gap-2 mb-2 lg:mb-0 lg:gap-5 flex-wrap lg:flex-nowrap opacity-90">
          <small className="font-bold"> </small>
          <small> </small>
          <small>&nbsp;&nbsp;대표자: 김병해</small>
        </div>
        <div className="flex justify-center items-center gap-2 lg:gap-5 flex-wrap lg:flex-nowrap mb-14 opacity-90"></div>
        <div className="lg:flex justify-center items-center gap-2 text-center lg:text-left opacity-90">
          <small>
            COPYRIGHT ⓒ 재단법인김병해서로장학재단.{" "}
            <br className="block lg:hidden" /> ALL RIGHTS RESERVED. DESIGN BY
            [SMILEEAN]
          </small>
          <br className="block lg:hidden" />
          <button
            type="button"
            onClick={() => (window.location.href = "/admin/login")}
            className=" relative w-fit h-fit text-xs py-0.5 px-3 mt-2 lg:mt-0 border rounded-md hover:bg-white hover:text-black"
          >
            <small>관리자</small>
          </button>
        </div>
      </div>
    </footer>
  );
};

export default MFooter;
