import { Link } from 'react-router-dom';

const ThreeBanner = () => {
    return (
        <div className="relative w-full h-full lg:px-[calc((100%-var(--container))/2)] py-4 lg:py-7 bg-[var(--gray-color)]">
            <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto grid grid-cols-3">
                <div className="relative w-full h-16 border-r border-white lg:p-1">
                    <Link to="https://www.nts.go.kr/" target="_blank">
                        <img
                            alt="important1"
                            className="!relative w-auto h-full max-w-[75px] lg:max-w-none mx-auto object-contain cursor-pointer"
                            src="/images/icon1.png"
                        />
                    </Link>
                </div>
                <div className="relative w-full h-16 border-r border-white lg:p-1">
                    <Link to="https://www.moe.go.kr/main.do" target="_blank">
                        <img
                            alt="important1"
                            className="!relative w-auto h-full max-w-[90px] lg:max-w-none mx-auto object-contain cursor-pointer"
                            src="/images/icon2.png"
                        />
                    </Link>
                </div>
                <div className="relative w-full h-16 lg:p-1">
                    <Link to="https://www.ice.go.kr/ice/main.do" target="_blank">
                        <img
                            alt="important1"
                            className="!relative w-auto h-full max-w-[95px] lg:max-w-none mx-auto object-contain cursor-pointer"
                            src="/images/icon3.png"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ThreeBanner;
