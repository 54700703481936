const Banner = () => {
    return (
        <div className=" relative w-[100vw] h-[calc((var(--vh,1vh)*100)-50px)] lg:h-[100vh]">
            <img
                alt="banner"
                src={process.env.PUBLIC_URL + '/images/banner2.jpg'}
                className=" absolute left-0 top-0 w-full h-full object-cover object-center"
            />
            <div className=" absolute left-0 top-[calc((var(--vh,1vh)*100)/4)] w-full h-fit text-white px-[calc((100%-var(--container))/2)]">
                <div className="relative w-full h-fit max-w-[var(--container-width)] mx-auto">
                    <h5 className="font-black delay-[0.5s] xl:mb-4 xl:text-xl opacity-0 blur animate-[blur-text-in_1s_forwards_cubic-bezier(0.11,0,0.5,0)]">
                        재단법인 김병해 서로장학재단
                    </h5>
                    <h2 className="gmarket xl:text-5xl xl:mb-3 font-black opacity-0 blur animate-[blur-text-in_1s_forwards_cubic-bezier(0.11,0,0.5,0)]">
                        더 큰 꿈을 키울 수 있도록,
                    </h2>
                    <h2 className=" gmarket xl:text-5xl font-black delay-[0.3s] opacity-0 blur animate-[blur-text-in_1s_forwards_cubic-bezier(0.11,0,0.5,0)]">
                        (재)김병해 서로장학재단이 함께 합니다.
                    </h2>
                </div>
            </div>
            <div className="absolute left-0 bottom-10 w-full h-fit">
                <div className=" relative w-fit h-fit mx-auto max-w-10 animate-bounce">
                    <img alt="mouse" src={process.env.PUBLIC_URL + '/icons/mouse.svg'} />
                </div>
            </div>
        </div>
    );
};

export default Banner;
