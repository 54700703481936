import { useEffect, useState } from "react";
import { Pagination } from "@mantine/core";
import axios from "axios";
import Category from "components/sub/Category";
import SubTitle from "components/sub/SubTitle";
import { JoinNotice } from "const/table";
import { dateToDate } from "const/dateFormat";

const Notice = () => {
  const [categoryId, setCategoryId] = useState(0);
  const [total, setTotal] = useState(0);
  const [categoryTotal, setCategoryTotal] = useState(total);
  const [data, setdata] = useState<JoinNotice[] | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    total < 0 &&
      axios
        .get("/api/board/total?categoryId=0")
        .then((res) => setTotal(res.data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(`/api/board/category/${categoryId}?page=${page - 1}&size=10`)
      .then((res) => setdata(res.data.data.content));
  }, [page, categoryId]);

  useEffect(() => {
    axios
      .get(`/api/board/total?categoryId=${categoryId}`)
      .then((res) => setCategoryTotal(res.data.data));
  }, [categoryId]);

  return (
    <div className=" relative w-full h-full">
      <SubTitle src="/images/sub5.jpg" category="커뮤니티" title="재단소식" />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <Category categoryId={categoryId} setCategoryId={setCategoryId} />
          <div className=" relative w-full h-full grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5 my-10">
            {data &&
              data.map((v, i) => (
                <div
                  data-aos="fade-up"
                  onClick={() => (window.location.href = `/notice/${v.id}`)}
                  className="relative w-full h-full p-5 border border-[var(--font-color)] hover:border-[var(--point-color)] rounded-md cursor-pointer aos-init aos-animate"
                >
                  <p className="p">{v.title}</p>
                  <div className=" relative w-full h-fit flex justify-between items-center">
                    <p
                      className={
                        dateToDate(v.startDate!, v.endDate!) === "진행중"
                          ? "lg:text-base text-point"
                          : "lg:text-base text-neutral-500"
                      }
                    >
                      {v.startDate && v.category.id === 2 && (
                        <b>{dateToDate(v.startDate!, v.endDate!)}</b>
                      )}
                    </p>
                    <div className="text-right mt-2">
                      <small className="text-xs">#{v.category.name}</small>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className=" relative w-fit h-fit mx-auto mt-10">
            <Pagination
              total={
                Math.ceil(categoryTotal / 10)
                  ? Math.ceil(categoryTotal / 10)
                  : 1
              }
              value={page}
              onChange={setPage}
              color="gray"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
