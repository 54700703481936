const Category = ({
  categoryId,
  setCategoryId,
}: {
  categoryId: number;
  setCategoryId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <div className=" relative w-full h-fit grid grid-cols-3 lg:grid-cols-5 text-center cursor-pointer">
      <div
        onClick={() => setCategoryId(0)}
        className={
          categoryId === 0
            ? " relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3"
            : " relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3"
        }
      >
        <p>전체보기</p>
      </div>
      <div
        onClick={() => setCategoryId(1)}
        className={
          categoryId === 1
            ? " relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3"
            : " relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3"
        }
      >
        <p>공지사항</p>
      </div>
      <div
        onClick={() => setCategoryId(2)}
        className={
          categoryId === 2
            ? " relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3"
            : " relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3"
        }
      >
        <p>장학신청</p>
      </div>
      <div
        onClick={() => setCategoryId(3)}
        className={
          categoryId === 3
            ? " relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3"
            : " relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3"
        }
      >
        <p>연간 기부금 모금액 및 활용실적</p>
      </div>
      <div
        onClick={() => setCategoryId(4)}
        className={
          categoryId === 4
            ? " relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3"
            : " relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3"
        }
      >
        <p>기타</p>
      </div>
    </div>
  );
};

export default Category;
