import axios from "axios";
import React from "react";
import { useState } from "react";

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();

  // const router = useRouter();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    if (!email || !password) return false;

    setLoading(true);

    await axios
      .post(`/api/admin/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        console.log(res.data);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("name", res.data.name);
      })
      .then(() => {
        if (localStorage.getItem("id"))
          window.location.href = "/admin/dashboard";
      })
      .catch((e) => {
        setSubmitError(e);
      });

    setLoading(false);
  };
  return (
    <div className="relative w-full h-full wrap mx-auto py-10 lg:py-20 px-2">
      <div className="mb-10 text-center">
        <h2 className="font-black text-2xl lg:text-4xl">관리자 로그인</h2>
      </div>
      <div className="max-w-[500px] mx-auto min-h-[40vh] px-5 lg:px-0">
        <label htmlFor="email">
          <b>아이디</b>
        </label>
        <input
          type="text"
          name="email"
          id="email"
          placeholder="이메일"
          value={email}
          onChange={handleEmailChange}
          required
          className="relative w-full border px-3 py-2 rounded-md mt-2"
        />
        <div className="h-5"></div>
        <label htmlFor="password">
          <b>비밀번호</b>
        </label>
        <input
          type="password"
          name="password"
          id="password"
          placeholder="비밀번호"
          value={password}
          onChange={handlePasswordChange}
          required
          className="relative w-full border px-3 py-2 rounded-md mt-2"
        />
        {/* <Link href="/forgot-password">Forgot Password?</Link> */}
        <div className="relative w-full h-full mt-10">
          <button
            type="button"
            title="Login"
            onClick={handleLogin}
            className="relative w-full h-fit bg-[var(--main-color)] text-white py-2 lg:mx-auto rounded-lg hover:scale-110 transition-transform duration-300"
            disabled={loading}
          >
            LOGIN
          </button>
        </div>
        <div className="text-center mt-3">
          {submitError && <small className="text-red-500">{submitError}</small>}
        </div>
      </div>
    </div>
  );
};

export default Login;
