import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";

import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import "@mantine/core/styles.css";

import AOS from "aos";
import "aos/dist/aos.css";

import "ckeditor5/ckeditor5.css";
import "react-datepicker/dist/react-datepicker.css";

import Layout from "./components/common/Layout";
import Home from "page/home";
import Greetings from "page/about/greetings";
import Location from "page/about/location";
import Business from "page/business";
import Notice from "page/board/notice";
import Contact from "page/board/contact";
import OneNotice from "page/board/notice/[id]";
import Login from "page/admin/login";
import Dashboard from "page/admin/dashboard";
import InquiryDetail from "page/admin/inquiry/[id]";
import Board from "page/admin/board";
import OneApplication from "page/admin/board/[id]/page";
import PostAndUpdateApplication from "page/admin/board/write";
import InquiryLists from "page/admin/inquiry";

function App() {
  function vh_height() {
    var vh = window.outerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    vh_height();
    AOS.init({
      delay: 50,
      duration: 400,
      easing: "ease-in-out",
    });

    if ((window.navigator as any).userAgentData) {
      if ((window.navigator as any).userAgentData.mobile) {
        localStorage.setItem("device", "mobile");
      } else {
        localStorage.setItem("device", "pc");
      }
    }
  }, []);

  return (
    <div className="App">
      <Notifications position="top-center" />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="about">
            <Route path="greetings" element={<Greetings />} />
            <Route path="location" element={<Location />} />
          </Route>
          <Route path="business" element={<Business />} />
          <Route path="notice" element={<Notice />} />
          <Route path="notice/:id" element={<OneNotice />} />
          <Route path="contact" element={<Contact />} />

          <Route path="admin">
            <Route path="login" element={<Login />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="inquiry" element={<InquiryLists />} />
            <Route path="inquiry/:id" element={<InquiryDetail />} />
            <Route path="board" element={<Board />} />
            <Route path="board/:id" element={<OneApplication />} />
            <Route path="post" element={<PostAndUpdateApplication />} />
            <Route
              path="post/application"
              element={<PostAndUpdateApplication />}
            />
            <Route path="update/:id" element={<PostAndUpdateApplication />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
