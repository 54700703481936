import {
  IconPinned,
  // IconBrandTelegram,
  IconUserQuestion,
} from "@tabler/icons-react";

export const ADMIN_NAV_LIST = [
  {
    id: 1,
    title: "게시판",
    pathname: "/admin/board",
    icon: IconPinned,
  },
  // {
  //   id: 2,
  //   title: "장학신청",
  //   pathname: "cation",
  //   icon: IconBrandTelegram,
  // },
  {
    id: 2,
    title: "고객문의",
    pathname: "/admin/inquiry",
    icon: IconUserQuestion,
  },
  //   {
  //     id: 4,
  //     title: "Q&A",
  //     pathname: "/admin/qna",
  //     icon: IconMessages,
  //   },
  //   {
  //     id: 5,
  //     title: "정관",
  //     pathname: "/admin/rule",
  //     icon: IconLicense,
  //   },
  // {
  //   id: 6,
  //   title: "관리자",
  //   pathname: "/admin/user",
  //   icon: IconUsers,
  // },
];
