const Service = () => {
    return (
        <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
            <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                <div className=" relative w-full h-fit mb-10 text-left">
                    <h3>진행사업</h3>
                    <p className=" ">
                        재단법인 김병해 서로장학재단은 교육 소외계층에 대한 지원 및 인재발굴을 위해 노력합니다.
                    </p>
                </div>
                <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-2 grid-rows-2 lg:grid-rows-1 gap-5">
                    <div
                        data-aos="fade-up"
                        className="relative w-full h-44 lg:h-72 xl:h-96 mb-2 bg-gray-300 aos-init aos-animate"
                    >
                        <div className=" absolute left-0 top-0 w-full h-full bg-black bg-opacity-50 hover:bg-opacity-0 text-white hover:text-black z-10 transition-all duration-500">
                            <p className=" absolute right-3 top-3 lg:right-5 lg:top-5 z-20">
                                소외계층 학생 장학금 지원 사업
                            </p>
                        </div>
                        <img
                            className=" relative w-full h-full object-cover object-center z-0"
                            src="/images/service1.jpg"
                            alt="service1"
                        />
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-delay="50"
                        className="relative w-full h-44 lg:h-72 xl:h-96 mb-2 bg-gray-300 text-white aos-init aos-animate"
                    >
                        <div className=" absolute left-0 top-0 w-full h-full bg-black bg-opacity-50 hover:bg-opacity-0 text-white hover:text-black z-10 transition-all duration-500">
                            <p className=" absolute right-3 top-3 lg:right-5 lg:top-5 z-20">
                                성적 우수자 학생 장학금 지원 사업
                            </p>
                        </div>
                        <img
                            className=" relative w-full h-full object-cover object-center z-0"
                            src="/images/service2.jpg"
                            alt="service1"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;
