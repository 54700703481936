import axios from "axios";
import OneBoard from "components/admin/OneBoard";
import { JoinNotice } from "const/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const OneApplication = () => {
  const params = useParams();

  const [data, setData] = useState<JoinNotice | null>();

  useEffect(() => {
    params.id &&
      axios.get(`/api/board/${params.id}`).then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full h-fit">
      {data && <OneBoard board={data} />}
    </div>
  );
};

export default OneApplication;
