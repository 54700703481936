import { JoinNotice } from "const/table";

const Portfolio = ({ data }: { data: JoinNotice[] }) => {
  return (
    <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] bg-[var(--gray-color)] py-14 lg:py-28">
      <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
        <div className=" relative w-full h-fit mb-10 text-left flex justify-between items-end">
          <div>
            <h3>재단 소식</h3>
            <p>재단법인 김병해 서로장학재단의 새로운 소식을 확인해보세요.</p>
          </div>
          <button
            onClick={() => (window.location.href = `/notice`)}
            type="button"
            className="hidden lg:block"
          >
            <p>전체보기</p>
          </button>
        </div>
        <div className=" relative w-full h-full grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5">
          {data.map((v, i) => (
            <div
              data-aos="fade-up"
              onClick={() => (window.location.href = `/notice/${v.id}`)}
              className="relative w-full h-full p-5 border border-[var(--font-color)] hover:border-[var(--point-color)] rounded-md cursor-pointer aos-init aos-animate"
            >
              <p>{v.title}</p>
              <div className="text-right mt-2">
                <small className="text-xs">#{v.category.name}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
