import { useState } from "react";
import axios from "axios";

import { notifications } from "@mantine/notifications";

import SubTitle from "components/sub/SubTitle";
import { IconCheck, IconExclamationCircle } from "@tabler/icons-react";

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");

  const postInquiry = () => {
    if (!name || !phone || !email || !title || !contents) {
      notifications.show({
        title: "등록 오류",
        message: "모든 항목을 입력해주세요.",
        autoClose: 2000,
        color: "red",
        icon: (
          <IconExclamationCircle
            style={{ width: "rem(18)", height: "rem(18)" }}
          />
        ),
      });
      return false;
    }
    axios
      .post(`/api/inquiry`, {
        name: name,
        phone: phone,
        email: email,
        title: title,
        contents: contents,
        device: sessionStorage.getItem("device"),
        ip: sessionStorage.getItem("ip"),
      })
      .then((res) => {
        if (res.data.code === "0000") {
          notifications.show({
            title: "문의 등록 완료",
            message: "문의 등록이 완료되었습니다. 메인화면으로 이동합니다.",
            autoClose: 2000,
            color: "gray",
            icon: <IconCheck style={{ width: "rem(18)", height: "rem(18)" }} />,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        }
      });
  };

  return (
    <div className=" relative w-full h-full">
      <SubTitle src="/images/sub6.jpg" category="커뮤니티" title="온라인문의" />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
            <h3>문의 내용</h3>
            <div className="line mb-3"></div>
            <div className=" relative w-full h-full grid grid-cols-1 grid-rows-4 lg:grid-cols-2 lg:grid-rows-2 gap-5 lg:gap-10">
              <div className=" relative w-full h-full lg:flex items-center gap-5">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="name2"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>담당자명</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="name2"
                  id="name2"
                  className="mt-2 lg:mt-0 !pl-20"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className=" relative w-full h-full lg:flex items-center gap-5">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="phone2"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>전화번호</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="phone2"
                  id="phone2"
                  maxLength={11}
                  className="mt-2 lg:mt-0 !pl-20"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className=" relative w-full h-full lg:flex items-center gap-5">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="email2"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>이메일</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="email2"
                  id="email2"
                  className="mt-2 lg:mt-0 !pl-16"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className=" relative w-full h-fit lg:grid grid-cols-2 gap-5 lg:gap-10">
              <div className="col-span-2 relative w-full h-fit">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="name2"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>문의 제목</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="title2"
                  id="title2"
                  className="mt-2 lg:mt-0 !pl-20"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-span-2 relative w-full h-fit">
                <div className="absolute left-2 top-2 w-fit h-full z-10 ">
                  <label
                    htmlFor="contents"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>문의 내용</h5>
                  </label>
                </div>
                <textarea
                  name="contents"
                  id="contents"
                  className="pt-10"
                  value={contents}
                  onChange={(e) => setContents(e.target.value)}
                ></textarea>
              </div>
              <div className="col-span-2 relative w-full h-fit flex items-center gap-5">
                <h5 className="font-black">약관 동의</h5>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    defaultChecked
                    checked
                    name="privacy"
                    id="privacy"
                    className=" relative w-4 h-4"
                  />
                  <label htmlFor="privacy">
                    개인정보처리방침에 동의합니다.
                  </label>
                </div>
              </div>
            </div>
            <div className=" relative w-fit h-fit my-5 lg:my-10 mx-auto">
              <button
                type="button"
                onClick={postInquiry}
                className=" relative w-fit h-fit py-1 px-10 lg:py-3 lg:px-32 bg-[var(--point-color)] text-white rounded-full hover:shadow-lg"
              >
                <h4>문의하기</h4>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
