import React from 'react';
import { useNavigate } from 'react-router-dom';

interface PagenationBtnType {
    slideIndex: number;
    setSlideIndex: React.Dispatch<React.SetStateAction<number>>;
}

const MNav = (props: { onNav: boolean; setOnNav: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const navigate = useNavigate();

    return (
        <nav
            className={
                props.onNav
                    ? 'fixed lg:hidden right-0 top-0 bg-white text-[var(--font-color)] w-4/5 h-[100vh] overflow-y-auto transition-all duration-500 z-50'
                    : 'fixed lg:hidden right-[-100%] top-0 bg-white text-[var(--font-color)] w-4/5 h-[100vh] overflow-y-auto transition-all duration-700 z-50'
            }
        >
            <button type="button" className=" absolute right-8 top-8 w-4 h-4" onClick={() => props.setOnNav(false)}>
                <img
                    className=" relative w-full h-full object-contain"
                    src={process.env.PUBLIC_URL + '/icons/icon_x.svg'}
                    alt="X"
                />
            </button>
            <ul className="grid grid-cols-1 pt-16">
                <li
                    className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                    onClick={() => {
                        props.setOnNav(false);
                    }}
                >
                    재단소개
                    <ul>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/about/greetings');
                            }}
                        >
                            인사말
                        </li>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/about/location');
                            }}
                        >
                            오시는 길
                        </li>
                    </ul>
                </li>
                <li className="text-xs py-2 px-6 opacity-25">
                    ●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●
                </li>
                <li
                    className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                    onClick={() => {
                        props.setOnNav(false);
                        navigate('/business');
                    }}
                >
                    재단사업
                </li>
                <li className="text-xs py-2 px-6 opacity-25">
                    ●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●
                </li>
                <li
                    className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                    onClick={() => {
                        props.setOnNav(false);
                    }}
                >
                    커뮤니티
                    <ul>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/notice');
                            }}
                        >
                            재단소식
                        </li>
                        <li
                            className="text-[17px] py-2 px-6 cursor-pointer"
                            onClick={() => {
                                props.setOnNav(false);
                                navigate('/contact');
                            }}
                        >
                            온라인문의
                        </li>
                    </ul>
                </li>

                <li className="text-xs py-2 px-6 opacity-25">
                    ●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;●
                </li>
                <li
                    className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                    onClick={() => {
                        props.setOnNav(false);
                        window.open('https://www.nts.go.kr/nts/cm/cntnts/cntntsView.do?mi=13386&cntntsId=109155');
                    }}
                >
                    공익위반신고
                </li>
            </ul>
        </nav>
    );
};

export default MNav;
